    <template>
      <div class="mt-40 ticket-message">
        <div class="mx-auto">
          <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
              <div class="d-flex align-items-center">
                <b-spinner small type="grow" variant="dark" class="mx-1"></b-spinner>
                <b-spinner type="grow" variant="dark" class="mx-1"></b-spinner>
                <b-spinner small type="grow" variant="dark" class="mx-1"></b-spinner>
              </div>
            </template>
          </b-overlay>
          <b-card class="border-0 p-0 ticket-content mb-4" v-for="(message, index) in messages" :key="index">
            <div v-if="index == 0" class="position-relative ticket-content-header p-3 rounded">
              <h4 class="fontWeightBold">{{ ticket.title }}
                <span class="float-left ">
                  <span v-if="ticket.status != 'closed'">
                    <span @click="closeTicket() , (disabled = true)" :disabled="disabled"
                      class="badge btn-answer fontWeightLight rounded" style="font-size:15px!important">
                      بستن تیکت
                    </span>
                  </span>
                  <span v-if="ticket.status == 'closed'" class=" badge badge-danger fontWeightLight"> بسته شده</span>
                </span>
              </h4>
              <small> تاریخ ثبت تیکت : {{ ticket.created_at | date }}</small>
            </div>
            <div class="ticket-content-messages p-3 rounded" :class="[message.user ? 'bg-user' : '']">
              <div v-if="message.user" class="user-detail border-bottom pb-2">
                <h6 class="fontWeightBold">
                 فروشنده : {{ message.user.name + ' ' + message.user.last_name}}
                </h6>
                <small class="text-gray-dark">
                  {{ message.created_at | date }}
                </small>
              </div>
              <div v-else class="admin-detail border-bottom pb-2">
                <h6 class="fontWeightBold">پشتیبانی</h6>
                <small class="text-gray-dark">
                  {{ message.created_at | date }}
                </small>
              </div>
              <div class="user-message text-right pt-2">
                {{ message.text }}
                <div if="message.files != ''">
                  <a v-for="item in message.files" :key="item.id" :href="item.url" target="_blank" class="text-light d-block">مشاهده فایل</a>
                </div>
              </div>
            </div>
          </b-card>
          <b-card class="ticket-answer p-3" v-if="ticket.status != 'closed'">
            <h5>ارسال پاسخ تیکت</h5>
            <!-- <span> برای پاسخ تیکت از فرم زیر میتوانید استفاده کنید. </span> -->
            <form @submit.prevent="sendAnswer(), (disabled = true)" class="mt-4">
              <b-form-group>
                <b-form-textarea v-model="answer" :disabled="disabled" rows="6" placeholder="متن تیکت">
                </b-form-textarea>
              </b-form-group>
              <div class="text-center">
                <button :disabled="disabled" class="btn btn-answer" type="submit">ارسال پاسخ</button>
              </div>
            </form>
          </b-card>
        </div>
      </div>
    </template>
    <script>
      import mixins from '../mixins/mixins.js'
      export default {
        mixins: [mixins],
        data() {
          return {
            show: true,
            disabled: false,
            url: "/api/admin/tickets",
            ticket: {},
            messages: [],
            answer: '',
            title:'نمایش تیکت'
          };
        },
        mounted() {
          if (this.$route.params.id) {
            this.$axios
              .get(this.$root.baseUrl + this.url + `/${this.$route.params.id}`)
              .then((response) => {
                this.ticket = response.data.data;
                this.messages = this.ticket.messages;
                this.show = false;
              });
          }
        },
        methods: {
          sendAnswer() {
            this.$axios.post(this.$root.baseUrl + this.url + `/${this.$route.params.id}/answer`, {
                text: this.answer
              }).then(response => {
                this.messages.push(response.data.data)
                this.$root.success_notification(response.data.message)
                this.disabled = false
                this.answer = ''
              })
              .catch(error => {
                this.disabled = false
                this.$root.error_notification(error)
              })
          },
          closeTicket() {
            window.swal({
                title: "تیکت بسته شود؟",
                text: "این عملیات غیر قابل بازگشت خواهد بود",
                icon: "warning",

                showCancelButton: true,
                showConfirmButton: true,
                dangerMode: true,
                buttons: ["خیر", "بله بسته شود"]
              })
              .then((willDelete) => {
                if (willDelete) {
                  this.$axios.post(this.$root.baseUrl + this.url + `/${this.$route.params.id}/close`)
                    .then((response) => {
                      this.ticket.status = 'closed'
                      this.disabled = false
                      window.swal({
                        icon: "success",
                        showConfirmButton: true,
                        text: response.data.message,
                        button: "باشه",
                      })
                    })
                    .catch(error => {
                      this.disabled = false
                      window.swal({
                        title: 'خطا',
                        showConfirmButton: true,
                        text: error.response.data.message,
                        button: "باشه",
                      })
                    })
                }
              })
              .catch(() => {
                this.disabled = false
              })
          }
        }
      };
    </script>
    <style scoped>
      .bg-user {
        background-color: rgb(0, 121, 254, 0.5);
      }

      .ticket-content-header {
        top: 0;
        right: 0;
        left: 0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        z-index: 10;
      }

      .user-detail,
      .admin-detail {
        font-size: 13px !important;
      }

      .user-detail small,
      .admin-detail small {
        font-size: 11px !important;
      }

      .ticket-answer h5 {
        font-family: iranSans !important;
        /* font-weight: 500; */
      }

      .btn-answer {
        background-color: #0079fe;
        color: #fff;
        border-radius: 30px;
        cursor: pointer;
      }
    </style>
    <style>
      .ticket-message .card-body {
        padding: 0;
      }

      .ticket-message .text-gray-dark {
        color: #666 !important;
      }
    </style>